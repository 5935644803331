import { useEffect, useRef, useState } from 'react';
import './CaptureTools.sass'
import { BsFillRecordFill, BsFillStopCircleFill, BsRecord, BsRecord2 } from 'react-icons/bs';
import { HiOutlineCamera, HiOutlineVideoCamera } from 'react-icons/hi';
import { TbRectangle } from 'react-icons/tb';
import { dockConnectionManager } from 'src/helper/HubConnectionManager';
import { djiCloudMethod } from 'src/services/common/constants';
import { DjiDockDroneCameraMode, DjiDockDroneRecordingState } from 'src/services/device/common/deviceConstants';
import useDeviceConnection from 'src/services/device/common/useDeviceConnection';
import useDeviceTelemetries from 'src/services/device/common/useDeviceTelemetries'

function CaptureTools({deviceId, subDeviceId}) {
  const subDeviceTelemetries = useDeviceTelemetries(subDeviceId);
  const cameraMode = subDeviceTelemetries?.cameras?.[0]?.camera_mode;
  const recordingState = subDeviceTelemetries?.cameras?.[0]?.recording_state;
  const connection = useDeviceConnection(deviceId);
  const [recordingSeconds, setRecordingSeconds] = useState(0);
  const recordingIntervalId = useRef(null);

  const getPayloadIndex = () => {
    let targetCameraId = null;

    if(subDeviceTelemetries?.['80-0-0']) {
      targetCameraId = "80-0-0";
    } else if(subDeviceTelemetries?.['81-0-0']) {
      targetCameraId = "81-0-0";
    } else {
      console.log('❌ No camera found for capture tools');
      return;
    }

    return targetCameraId;
  }

  const switchCameraMode = () => {
    const newMode = cameraMode === DjiDockDroneCameraMode.CAPTURE ? DjiDockDroneCameraMode.RECORDING : DjiDockDroneCameraMode.CAPTURE;

    dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.group?.send?.services, {
      method: djiCloudMethod.liveFlight.CAMERA_MODE_SWITHCH,
      data: {
        camera_mode: newMode,
        payload_index: getPayloadIndex()
      }
    });
  }

  const captureImage = () => {
    dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.group?.send?.services, {
      method: djiCloudMethod.liveFlight.CAMERA_PHOTO_TAKE,
      data: {
        payload_index: getPayloadIndex()
      }
    });
  }

  const toggleVideoRecord = () => {
    const method = recordingState === DjiDockDroneRecordingState.RECORDING ? djiCloudMethod.liveFlight.CAMERA_RECORDING_STOP : djiCloudMethod.liveFlight.CAMERA_RECORDING_START;

    dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.group?.send?.services, {
      method: method,
      data: {
        payload_index: getPayloadIndex()
      }
    });
  }

  useEffect(() => {
    if(recordingState === DjiDockDroneRecordingState.RECORDING) {
      recordingIntervalId.current = setInterval(() => {
        setRecordingSeconds((prev) => prev + 1);
      }, 1000);

      return () => clearInterval(recordingIntervalId.current);
    } else {
      setRecordingSeconds(0);
      clearInterval(recordingIntervalId.current);
    }
  }, [recordingState]);

  return (
    <div className="capture-tools">
      <div className="camera-mode" onClick={switchCameraMode}>
        {cameraMode === DjiDockDroneCameraMode.CAPTURE ? (
          <HiOutlineVideoCamera tiitle="Switch to recording mode" />
        ) : (
          <HiOutlineCamera title="Switch to photo mode" />
        )}
      </div>
      <div className="capture-button">
        {cameraMode === DjiDockDroneCameraMode.RECORDING ? 
          recordingState === DjiDockDroneRecordingState.RECORDING ? (
            <BsFillStopCircleFill onClick={toggleVideoRecord} color="red" title="Stop Recording" />
          ) : (
            <BsFillRecordFill onClick={toggleVideoRecord} color="red" title="Start Recording" />
          )
        : (
          <BsRecord onClick={captureImage} title="Take Photo" />
        )}
      </div>
      {recordingSeconds ? (
        <div className="capture-seconds">
          <span>
            {Math.floor(recordingSeconds / 60).toString().padStart(2, '0')}:
            {(recordingSeconds % 60).toString().padStart(2, '0')}
          </span>
        </div>
      ) : null}
    </div>
  )
}

export default CaptureTools