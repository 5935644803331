import React, { useEffect, useState } from "react";
import "./Select.sass";
import SmallAlert from "../SmallAlert/SmallAlert";
import PropTypes from "prop-types";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import OutSideDetector from "../OutsideDetector/OutSideDetector";
import classNames from "classnames";

const Select = (props) => {
  const {
    items,
    title,
    isRequired,
    name,
    placeholder,
    onChange,
    value,
    current,
    index,
    status,
    width,
    background,
    darkMode,
  } = props;
  const [select, setSelect] = useState({ open: false });

  useEffect(() => {
    if (items?.find((item) => item.value === current)?.name !== undefined)
      setVal(items.find((item) => item.value === current)?.name);
  }, [current]);

  const toggle = (event) => {
    event.stopPropagation();
    setSelect({ ...select, open: !select.open });
  };

  const toggleSelect = (value) => {
    setSelect({ ...select, open: false, name: value });
    setVal(value);
    let idx = 0;
    document.querySelectorAll(".select-box-input").forEach((item, index) => {
      if (item.name === name) {
        idx = index;
      }
    });
    const input = document.querySelectorAll(".select-box-input")[idx];
    var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      "value"
    ).set;
    nativeInputValueSetter.call(input, value);
    var ev2 = new Event("input", { bubbles: true });
    input.dispatchEvent(ev2);
  };

  const [val, setVal] = useState(value !== undefined ? value : "");
  const changeHandler = (event) => {
    setVal(items.filter((item) => item.value === event.target.value)[0]?.name);
  };

  return (
    <OutSideDetector
      detect={() => {
        setSelect({ ...select, open: false });
      }}
      className="select-box" width={width}
    >

      <label htmlFor="input">
        {title} {isRequired ? "*" : ""}
      </label>
      <div onClick={toggle} className="select-box-header">
        {select.open ? (
          <FaChevronUp className="select-box-arrow" />
        ) : (
          <FaChevronDown className="select-box-arrow" />
        )}

        <input
          autoComplete="off"
          style={{ background: background || darkMode ? 'var(--dark-mode-backgorund)' : '', color: darkMode ? 'var(--dark-mode-text)' : '' }}
          className={`select-box-input ${select.open
            ? "open"
            : "" || (status !== undefined && status.alert === true)
              ? "select-box-input-" + status.type
              : ""
            }`}
          type="text"
          name={name}
          id={name}
          value={val}
          placeholder={placeholder}
          onChange={changeHandler}
          onInput={onChange}
          data-index={index}
          readOnly
        />
      </div>

      {select.open && (
        <div className={classNames("select-box-items-container", { 'dark-mode': darkMode })}>
          <ul className="select-box-items">
            {items.map((item) => (
              <li
                className="select-box-item"
                key={item.id}
                onClick={() => toggleSelect(item.value)}
              >
                <span className="select-box-value">{item.name}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
      {status !== undefined && status.alert && (
        <SmallAlert
          alert={status.alert}
          alertType={status.type}
          message={status.message}
          hideIcon={true}
          position="right"
        />
      )}

    </OutSideDetector>
  );
};
Select.propTypes = {
  items: PropTypes.array.isRequired,
};
Select.defaultProps = {
  background: "#f4f7fc",
  width: "100%",
};
export default Select;
