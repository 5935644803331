import { get } from "http";
import { deviceModels, deviceTypes } from "src/helper/constants"
import store from "src/redux/configureStore"

export const getDeviceModelInfo = (model, subModel) => {
  let realModel, realSubModel, modelInfo;

  if (model?.indexOf?.('-') > -1) {
    const modelParts = model.split('-');

    if (modelParts?.length === 2)
      [realModel, realSubModel] = modelParts;
    else if(modelParts?.length === 3)
      [, realModel, realSubModel] = modelParts;
  }
  else if (subModel === undefined) {
    modelInfo = deviceModels.find(item => item.model === model);
  }

  if (!modelInfo) {
    realModel = parseInt(model);
    realSubModel = subModel !== undefined && parseInt(subModel);

    modelInfo = deviceModels.find(item => {
      return item.model === realModel && (realSubModel === undefined || item.subModel === realSubModel);
    })
  }

  if (!modelInfo) return null;

  modelInfo = {
    ...modelInfo,
    modelNumber: realModel ?? modelInfo.modelNumber,
    subModelNumber: realSubModel ?? modelInfo.subModelNumber,
  };

  return modelInfo;
}

export const getDeviceTelemetries = (deviceId) => {
  return store.getState().deviceService.deviceTelemetries[deviceId];
}

export const getSubDeviceTelemetries = (deviceId) => {
  const deviceTelemetries = getDeviceTelemetries(deviceId);

  if(deviceTelemetries?.sub_device?.device_sn)
    return getDeviceTelemetries(deviceTelemetries.sub_device.device_sn);
  else return null;
}

export const getDeviceConnectionGroups = (deviceId) => {
  const state = store.getState();
  const deviceType = state.deviceService.devices.find(device => device.id === deviceId)?.type;

  if (deviceType === deviceTypes.DOCK) {
    return state.deviceService.dockConnections.find(connection => connection.deviceId === deviceId)?.group;
  }
  else if (deviceType === deviceTypes.DRONE) {
    return state.deviceService.onboardConnections.find(connection => connection.deviceId === deviceId)?.group;
  }
  else return null;
}

export const getDeviceInfo = (deviceIdOrSerial) => {
  return store.getState().deviceService.devices?.find(item => item.id === deviceIdOrSerial || item.serialNumber === deviceIdOrSerial);
}

export const getSubDeviceInfo = (deviceId) => {
  const deviceTelemetries = getDeviceTelemetries(deviceId);

  if(deviceTelemetries?.sub_device?.device_sn)
    return getDeviceInfo(deviceTelemetries.sub_device.device_sn);
}