import './DockStage.sass'
import { StageElement } from '../../OperationDashboard'
import DockCamera from '../DockCamera/DockCamera'
import { useState } from 'react';
import classNames from 'classnames';
import StageStatusbar from '../StageStatusbar/StageStatusbar';
import { MdFolder } from 'react-icons/md';
import DeviceMediaList from '../DeviceMediaList/DeviceMediaList';

function DockStage({ elementType, entity, missionController }) {
  const [isMinimized, setIsMinimized] = useState();

  const handleOnCameraMinimize = (state) => {
    setIsMinimized(state);
  }

  return (
    <div className={classNames("dock-stage", {'minimized': isMinimized})}>
      <div className="stage-main">
        {elementType === StageElement.DOCK && (
          <DockCamera key={"dock-camera-" + entity?.id} deviceId={entity?.id} expanded={true} pip={true} missionController={missionController} onMinimize={handleOnCameraMinimize} />
        )}
        {elementType === StageElement.DOCK_DRONE && (
          <DockCamera key={"dock-drone-camera-"  + entity?.id} deviceId={entity?.id} expanded={true} subDevice={true} onMinimize={handleOnCameraMinimize} />
        )}
      </div>
      {/* <StageStatusbar tabs={[
        { 
          id: 'device-media-list', 
          title: 'Captured Media', 
          icon: <MdFolder /> , 
          content: (setPanelHeaderLeft, setPanelHeaderTools) => (
            <DeviceMediaList 
              bucketId='dff9ac48-09bd-4678-9f5e-e311f9620911' 
              bucketName='322549b3-fd4c-4a74-afe0-97ee66aa3093-sing-s3-bucket' 
              bucketRegion='ap-southeast-1'
              onPanelHeaderLeftChange={setPanelHeaderLeft}
            />
          ) 
        },
      ]} /> */}
    </div>
  )
}

export default DockStage