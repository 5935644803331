import './EntityListItem.sass'
import useDeviceInfo from 'src/services/device/common/useDeviceInfo';
import useDeviceTelemetries from 'src/services/device/common/useDeviceTelemetries';
import { EntityType } from '../ExploreApp';
import classNames from 'classnames';
import { DeviceOnlineStatus } from 'src/helper/useOnboardDeviceList';
import Badge from 'src/hci/common/Badge/Badge';
import useMissionInfo from 'src/services/mission/common/useMissionInfo';
import { MissionStatus, NotActiveMissionStatusSet } from 'src/services/mission/common/missionConstants';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedEntity } from '../ExploreAppSlice';
import { setActivePathId, setLastSelectedMarker, setNewFlyCoordinates } from 'src/components/DeviceMap/DeviceMapSlice';
import { isValidCoordinate } from 'src/helper/utils';

export default function EntityListItem({ entity, type }) {
  const dispatch = useDispatch();
  const deviceInfo = useDeviceInfo(entity?.id);
  const telemetries = useDeviceTelemetries(entity?.id);
  const missionInfo = useMissionInfo(item => item.deviceId === entity?.id);
  const selectedEntity = useSelector(state => state.exploreApp.selectedEntity);
  const mapMarkers = useSelector((state) => state.deviceMap.markers);

  const entityName = deviceInfo?.name || 
  deviceInfo?.title || 
  entity?.name || 
  (entity?.user?.displayName && `${entity?.user?.displayName} (${entity?.user?.role})`) || 
  'Unknown';
  
  const entityType = type || deviceInfo?.type || entity?.type;
  const entityOnlineStatus = deviceInfo?.onlineStatus || (entityType === EntityType.MOBILE_DEVICE && DeviceOnlineStatus.ONLINE);
  let status;
  
  if(entityOnlineStatus !== DeviceOnlineStatus.ONLINE) {
    status = 'offline';
  }
  else if(missionInfo?.status && !NotActiveMissionStatusSet.includes(missionInfo?.status)) {
    status = missionInfo?.status;
  } 
  else if(missionInfo?.status === MissionStatus.IDLE) {
    status = 'In-Mission';
  }
  else {
    status = entityType === EntityType.MOBILE_DEVICE ? 'online' : 'idle';
  }

  const initializing = deviceInfo?.initialized === false;
  // const disabled = (entityType === EntityType.DOCK || entityType === EntityType.DRONE) && (
  //   entityOnlineStatus !== DeviceOnlineStatus.ONLINE
  // );
  const disabled = (entityType === EntityType.DOCK || entityType === EntityType.DRONE) && (
    !deviceInfo?.initialized || entityOnlineStatus !== DeviceOnlineStatus.ONLINE
  );

  const handleOnClick = () => {
    if(disabled)
      return;

    dispatch(setSelectedEntity(null));
    
    setTimeout(() => {
      dispatch(setSelectedEntity({
        ...entity,
        entityType
      }));
    }, 10);

    const mapMarker = mapMarkers?.find(marker => marker.data?.sourceId === entity.id);
    dispatch(setLastSelectedMarker(mapMarker || null));

    if(mapMarker)
      dispatch(setNewFlyCoordinates([mapMarker.long, mapMarker.lat]));

    if(entityType === EntityType.DOCK || entityType === EntityType.DRONE) {
      dispatch(setActivePathId(entity.id));
    }
  }

  if(
    (entityOnlineStatus !== DeviceOnlineStatus.ONLINE &&
    entityType === EntityType.DRONE) 
    ||
    (entityType === EntityType.DOCK && 
    !isValidCoordinate(deviceInfo?.coordinates))
  ) return null;

  return (
    <div 
      onClick={handleOnClick} 
      id={entityType + ' ' + (deviceInfo.serialNumber || entityName)}
      title={entity?.id}
      className={classNames(
        'entity-list-item', {
          'status-online': entityOnlineStatus === DeviceOnlineStatus.ONLINE, 
          'selected': entity?.id === selectedEntity?.id,
          'initializing': initializing,
          'disabled': disabled,
        }
      )
    }>
      <div className="entity-header">
        {![EntityType.OPERATION_UNIT].includes(entityType) && (
          <div 
            className={classNames(
              "status-indicator", 
              {
                'status-online': entityOnlineStatus === DeviceOnlineStatus.ONLINE || entityType === EntityType.MOBILE_DEVICE, 
                'status-busy': status !== 'idle' && status !== 'offline' && entityType !== EntityType.MOBILE_DEVICE, 
              }
            )}
          ></div>
        )}
        <div className="title">{entityName}</div>
        {!!status && (status !== 'offline' || entityType === EntityType.DOCK) && (
          <Badge 
            color={status === 'online' || status === 'idle' ? 'green' : status === 'offline' ? 'gray' : status ? 'red' : 'white'} 
            className="status-text"
          >
            {initializing ? 'INIT' : status?.toUpperCase()}
          </Badge>
        )}
      </div>
    </div>
  );
}
