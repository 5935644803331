import "./AdminNavigation.sass";
import { useSpring, animated } from "react-spring";
import { useEffect, useState } from "react";
import OutSideDetector from "../../../ui/OutsideDetector/OutSideDetector";
import AdminNavigationItem from "./AdminNavigationItem/AdminNavigationItem";
import { MdClose } from "react-icons/md";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AdminNavbarMenu from "../AdminNavbar/AdminNavbarMenu/AdminNavbarMenu";
import { adminItems } from "../../../helper/constants";
import { usePrivileges } from "../../../helper/AccountStateProvider";
import { Link } from "react-router-dom";
import { IoAppsOutline } from "react-icons/io5";
import DesktopSize from "../../../ui/DesktopSize/DesktopSize";
import { useTheme } from "../../../helper/ThemeProvider";

function AdminNavigation({ fixed, isOpen, toggle, isToggling }) {
  const [open, setOpen] = useState(isOpen);
  const { privileges } = usePrivileges();
  const { currentContext } = useTheme();
  const toggleOff = () => {
    setOpen(false);
    isToggling();
  };

  const delayedToggleOff = () => {
    isToggling();
    setTimeout(() => {
      setOpen(false);
    }, 300);
  };

  const props = useSpring({
    to: {
      opacity: open ? 1 : 0,
      transform: `translateX(${open ? 0 : -100}px)`,
    },
    from: { opacity: 0, transform: "translateX(-100px)" },
    onRest: () => {
      if (!open) toggle();
    },
  });
  const location = useLocation();
  const [menuItems, setMenuItems] = useState([]);

  const [home, setHome] = useState(false);
  useEffect(() => {
    if (privileges.admins !== undefined)
      setMenuItems(
        [
          ...adminItems.filter((item) =>
            privileges.admins.map((a) => a.id).includes(item.name) ||
            item.name === 'dashboard' ||
            item.name === 'aws-s3'
          ),
        ].map((item) => ({
          ...item,
          active: location.pathname.includes(item.name),
        }))
      );
  }, [privileges.admins, location]);

  useEffect(() => { }, [menuItems]);

  return fixed ? (
    <div
      className="admin-navigation"
      style={{ background: currentContext.theme.secondColor }}
    >
      <ul className="admin-navigation-container">
        {menuItems?.map((item, index) => (
          <li
            key={index}
            className={`admin-navigation-item-container ${item.active ? "admin-navigation-active-item" : ""
              }`}
          >
            <AdminNavigationItem
              color={item.active ? "#2699fb" : "#455060"}
              navItem={item}
            />
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <OutSideDetector detect={toggleOff}>
      <animated.div
        className="admin-navigation"
        style={{ ...props, background: currentContext.theme.secondColor }}
      >
        <span className="admin-navigation-close">
          <AdminNavbarMenu color="white" />
          <MdClose onClick={toggleOff} />
        </span>
        <ul className="admin-navigation-container">
          {menuItems.map((item, index) => (
            <li
              key={index}
              className={`admin-navigation-item-container ${item.active ? "admin-navigation-active-item" : ""
                }`}
            >
              <AdminNavigationItem
                color={item.active ? "#2699fb" : "#455060"}
                navItem={item}
                navItemClicked={() => {
                  delayedToggleOff(item);
                }}
              />
            </li>
          ))}
        </ul>
      </animated.div>
    </OutSideDetector>
  );
}

export default AdminNavigation;
