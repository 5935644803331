import { useEffect, useState } from "react";
import { MdDeleteForever, MdRefresh, MdGroup } from "react-icons/md";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import AdminContentAreaContainer from "../../AdminContentAreaContainer/AdminContentAreaContainer";
import "./DockManagement.sass";
import { usePrivileges } from "../../../../helper/AccountStateProvider";
import { getList, removeDocks } from "./DockManagementSlice";
import { FaUserCircle, FaUserEdit } from "react-icons/fa";
import GroupAssignment from "./GroupAssignment/GroupAssignment";
import EditDock from "./EditDock/EditDock";
import EditDockOptions from "./EditDockOptions/EditDockOptions";
import LabeledIcon from "src/ui/LabeledIcon/LabeledIcon";
import Avatar from "src/ui/Avatar/Avatar";
import SVGAvatar from "src/ui/SVGAvatar/SVGAvatar";
import EntityName from "src/ui/EntityName/EntityName";

function DockManagement() {
  const dispatch = useDispatch();
  const api = useSelector((state) => state.api.state);
  const dock = useSelector((state) => state.dock);
  const { privileges } = usePrivileges();
  const [showOperationManager, setShowOperationManager] = useState(false);
  const [operationManagerHandler, setOperationManagerHandler] = useState(false);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (privileges.admins !== undefined) {
      if (
        privileges.admins.filter((item) => item.id === "docks")[0] !==
        undefined
      )
        setPermissions([
          ...privileges.admins.filter((item) => item.id === "docks")[0]
            ?.privileges,
        ]);
    }
  }, [privileges.admins]);

  const toggleOperationManagerOff = () => {
    setShowOperationManager(false);
  };
  const toggleManagerOperationOn = () => {
    setShowOperationManager(true);
    setOperationManagerHandler(true);
  };

  const removeDockGenerator = (data) => {
    dispatch(removeDocks(data[0]?.id));
  };

  const refreshGenerator = () => {
    dispatch(getList());
  };

  useEffect(() => {
    if (api !== undefined) {
      dispatch(getList());
    }
  }, [api]);

  useEffect(() => {
    if (dock?.list?.data !== undefined) {
      const docks = [];
      dock.list.data.docks.map((item) =>
        docks.push({
          id: item.id,
          model: item.model,
          serialNumber: item.serialNumber,
          manufacturer: item.manufacturer,
          dfrSetting: item.dfrSetting,
          registeredBy: (
            <EntityName
              item={{
                title: item.registeredBy.displayName,
                subtitle: item.registeredBy.emailAddress,
                icon: item.registeredBy.avatar ? (
                  <Avatar
                    image={item.registeredBy.avatar}
                    isCovered={true}
                    width="2em"
                    height="2em"
                  />
                ) : (
                  <SVGAvatar />
                ),
              }}
            />
          ),
          sdk: item.sdk,
          videoStreamingRegion: item.videoStreamingRegion,
          dataStreamingRegion: item.dataStreamingRegion,
          unitOfMeasurement: item.unitOfMeasurement,
          dockName: item.dockName,
          firmwareVersion: item.firmwareVersion,
          groups: item.groups,
          active: false,
        })
      );
      setRows({
        ...rows,
        values: [...docks],
      });
    }
  }, [dock?.list]);

  const histories = [
    { title: "Home", link: "/admin/home" },
    { title: "Docks", link: "/admin/docks" },
  ];
  const [features, setFeatures] = useState([]);
  const [options, setOptions] = useState([]);

  const dataOptions = [
    {
      title: "Delete Dock",
      icon: <MdDeleteForever />,
      name: "delete_dock",
      active: true,
    },
    {
      title: "Edit Dock",
      icon: <FaUserEdit />,
      name: "edit_dock",
      active: true,
      selected: true,
    },
  ];

  const userOptions = [
    {
      title: "Create Dock",
      icon: <AiOutlineUsergroupAdd />,
      name: "create_dock",
      active: true,
    },
    {
      title: "Refresh",
      icon: <MdRefresh />,
      name: "get_docks",
      active: true,
    },
    {
      title: "Delete Dock",
      icon: <MdDeleteForever />,
      name: "delete_dock",
      type: "dependent",
      singular: true,
      active: false,
    },
    {
      title: "Edit Dock",
      icon: <FaUserEdit />,
      name: "edit_dock",
      type: "dependent",
      active: false,
      singular: true,
    },
    {
      title: "Group Assignment",
      icon: <MdGroup />,
      name: "dock_groups_assignment",
      type: "dependent",
      active: false,
      singular: true,
    },
  ];

  const userFeatures = [
    {
      id: "create_dock",
      title: "Create Dock",
      description: "You can create a dock ",
      icon: <AiOutlineUsergroupAdd />,
      color: "#2699fb",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [],
    },
    {
      id: "delete_dock",
      title: "Delete Dock(s)",
      confirm: "Are you sure for deleting selected dock?",
      description: "You can delete a dock under your company account",
      type: "dialog",
      actionGenerator: removeDockGenerator,
    },
    {
      id: "get_docks",
      type: "no-op",
      actionGenerator: refreshGenerator,
    },
    {
      id: "edit_dock",
      title: "Edit Dock",
      description: "Please input all measurement units in meters. If the 'imperial' option is selected under 'edit dock' settings, the system will automatically convert these measurements to imperial units across all applications.",
      icon: <FaUserEdit />,
      color: "#2699fd",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [
        {
          name: "edit_dock",
          active: true,
          visible: true,
          locked: false,
          noBorder: true,
          fullHeight: true,
          tags: ["dock/update", "dock/updateDfr", "group/details", "dock/groupAssignment"],
          activity: (
            <EditDockOptions
              onOpen={toggleManagerOperationOn}
              onConfirm={toggleOperationManagerOff}
            />
          ),
        },
      ],
    },
    {
      id: "dock_groups_assignment",
      title: "Edit Dock",
      description: "You edit dock properties for selected dock.",
      icon: <FaUserEdit />,
      color: "#2699fd",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [
        {
          name: "edit_dock",
          active: true,
          visible: true,
          locked: false,
          noBorder: true,
          fullHeight: true,
          tags: ["dock/update", "dock/updateDfr", "group/details", "dock/groupAssignment"],
          activity: (
            <EditDockOptions
              onOpen={toggleManagerOperationOn}
              onConfirm={toggleOperationManagerOff}
              defaultIndex={1}
            />
          ),
        },
      ],
    },
  ];

  const [rows, setRows] = useState({
    headers: [
      { title: "Id", value: "id", status: false },
      { title: "Name", value: "dockName", status: false },
      { title: "Model", value: "model", status: true },
      { title: "Serial Number", value: "serialNumber", status: true },
      { title: "Manufacturer", value: "manufacturer", status: true },
      { title: "Registered By", value: "registeredBy", status: true },
      { title: "SDK", value: "sdk", status: false },
      {
        title: "Data Streaming Region",
        value: "dataStreamingRegion",
        status: true,
      },
      {
        title: "Current Firmware Version",
        value: "firmwareVersion",
        status: false,
      },
    ],
    values: [],
    options: [],
  });

  useEffect(() => {
    if (dock.create.status === "success") {
      dispatch(getList());
    }
  }, [dock.create]);

  useEffect(() => {
    if (dock.groupAssignment.status === "success") {
      dispatch(getList());
    }
  }, [dock.groupAssignment]);

  useEffect(() => {
    if (dock.remove.status === "success") {
      dispatch(getList());
    }
  }, [dock.remove]);

  useEffect(() => {
    if (dock.updateDfr.status === "success") {
      dispatch(getList());
    }
  }, [dock.updateDfr]);

  useEffect(() => {
    if (dock.update.status === "success") {
      dispatch(getList());
    }
  }, [dock.update]);

  useEffect(() => {
    setFeatures([
      ...userFeatures
        .filter((feature) => permissions.includes(feature.id))
        .map((f) => ({
          ...f,
          steps: f.steps?.filter((step) => permissions.includes(step.name)),
        })),
    ]);
    setOptions([
      ...userOptions.filter((option) => permissions.includes(option.name)),
    ]);
    setRows({
      ...rows,
      options: dataOptions.filter((option) =>
        permissions.includes(option.name)
      ),
    });
  }, [permissions]);

  const [currentFeature, setCurrentFeature] = useState();
  const [currentFeatureLabel, setCurrentFeatureLabel] = useState("edit_dock");
  const onFeatureChanged = (name) => {
    if (["list_docks", "delete_dock"].includes(name)) return;
    setCurrentFeatureLabel(name);
    setCurrentFeature(
      userFeatures
        .filter((feature) => feature.id === name)
        .map((f) => ({
          ...f,
          steps: f.steps?.filter((step) => permissions.includes(step.name)),
        }))[0]
    );
    toggleManagerOperationOn();
  };
  const onRowChange = () => {
    setCurrentFeature(
      userFeatures
        .filter((feature) => feature.id === currentFeatureLabel)
        .map((f) => ({
          ...f,
          steps: f.steps?.filter((step) => permissions.includes(step.name)),
        }))[0]
    );
    toggleManagerOperationOn();
  };

  return (
    <AdminContentAreaContainer
      histories={histories}
      title="Dock Management"
      data={rows}
      features={[...features]}
      operations={[...options]}
      table={{
        multiSelect: false,
        tags: ["dock/list", "dock/remove"],
        show: true,
      }}
      operationManager={showOperationManager}
      managerHandler={operationManagerHandler}
      onRowChange={onRowChange}
      currentFeature={currentFeature}
      onCurrentFeatureChanged={onFeatureChanged}
    />
  );
}

export default DockManagement;
