import useDockList, { DeviceOnlineStatus } from "../../helper/useDockList"
import useOnboardDeviceList from "../../helper/useOnboardDeviceList";
import { dockConnectionManager, frontendConnectionManager, mobileConnectionManager, onboardConnectionManager } from "../../helper/HubConnectionManager";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { getDockWsPermissions, getMobileWsPermissions, getOnboardWsPermissions, cleanSubDevicePermissionRequest, setDeviceHms, setDeviceInfo, setDeviceTelemetries, setDevices, setDockConnectionInfo, setMobileConnectionInfo, setOnboardConnectionInfo, setSubDevicePermissionRequest, setSubDevicePermissionRequestProgress, startDockConnection, startMobileConnection, startOnboardConnection, removeDockConnectionInfo } from "./DeviceServiceSlice";
import { deviceTypes } from "src/helper/constants";
import { djiCloudCustomMethod } from "../common/constants";
import { HubEventName, HubName } from "src/helper/HubConnection";
import { onboardWaypointAction } from "../mission/common/missionConstants";
import { generateId } from "src/helper/utils";

export const deviceConnectionStatus = {
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
  CONNECTING: 'connecting',
  INITIALIZING: 'initializing',
  DISCONNECTING: 'disconnecting',
};

const dockDefaultJoinGroups = [
  'events',
  'osd',
  'requests',
  'services_reply',
  'commands_reply',
  'state',
  'set_reply',
  'status',
  'status_reply',
  'up'
];

const dockSubDeviceDefaultJoinGroups = [
  'events',
  'osd',
  'requests',
  'services_reply',
  'commands_reply',
  'state',
  'set_reply',
  'status',
  'status_reply',
];

const onboardDefaultJoinGroups = [
  'lowFreqChannel',
  'highFreqChannel',
  'onDemandChannel',
];

const mobileDefaultJoinGroups = [
  'mobileStatus',
];

const onboardTelemetryGroups = [
  'lowFreqChannel',
  'highFreqChannel',
];

const dockTelemetryGroups = ['osd'];
const dockSubDeviceTelemetryGroups = ['osd'];

export const useDeviceService = (middlewares = []) => {
  const dispatch = useDispatch();
  const store = useStore();
  const { docks } = useDockList('devices_services');
  const { devices: onboardDevices } = useOnboardDeviceList('devices_services');
  const deviceService = useSelector((state) => state.deviceService);
  const frontendOid = useSelector((state) => state.api.state?.oid);
  const subDeviceMessageIds = useRef({});
  const lastTelemetryTimestamp = useRef({});
  const initialTelemetriesTimeoutId = useRef();
  const initialTelemetriesReceived = useRef(false);
  const groupsJoinStatus = useRef({});

  const devicesCombined = useMemo(() => {
    const dronesData = onboardDevices?.map((device) => {
      const currDeviceData = store.getState().deviceService.devices.find(item => item.id === device.id);

      return {
        id: device.id,
        serialNumber: device.serialNumber,
        name: device.deviceName || device.manufacturer + ' drone ' + device.serialNumber.slice(-3),
        onlineStatus: device.onlineStatus,
        coordinates: null,
        type: deviceTypes.DRONE,
        details: device,
        initialized: device.onlineStatus === DeviceOnlineStatus.ONLINE ? currDeviceData?.initialized : undefined,
      };
    });

    const docksData = docks?.map((device) => {
      const currDeviceData = store.getState().deviceService.devices.find(item => item.id === device.id);

      return {
        id: device.id,
        serialNumber: device.serialNumber,
        name: device.dockName || device.manufacturer + ' dock ' + device.serialNumber.slice(-3),
        onlineStatus: device.onlineStatus,
        coordinates: device.lastVisitedLocation || null,
        type: deviceTypes.DOCK,
        details: device,
        initialized: device.onlineStatus === DeviceOnlineStatus.ONLINE ? currDeviceData?.initialized : undefined,
      };
    });

    return [...dronesData, ...docksData];
  }, [docks, onboardDevices]);

  const externalMessageHandlers = middlewares?.map((middleware) => middleware())
    .filter((middleware) => middleware.onMessage)
    .map((middleware) => middleware.onMessage) || [];

  // Listen to all device messages
  const onMessage = useCallback((message, deviceType) => {
    if(!message.fromUserId)
      return;

    message.deviceType = deviceType;
    message.targetId = message.fromUserId.split('_')[0];

    externalMessageHandlers.forEach(item => item(message, { 
      devices: devicesCombined, 
    }));

    if(deviceType === deviceTypes.DOCK) {
      const group = message.group.split('/').pop();
      if(group === 'up') {
        // console.log('📦 DOCK_UP', atob(message.data));

        // const currLocalDockUpMessages = localStorage.getItem('dock_up_messages') || '[]';
        // const dockUpMessages = JSON.parse(currLocalDockUpMessages);
        // const currFormatedTime = new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });

        // dockUpMessages.push({
        //   time: currFormatedTime,
        //   message: atob(message.data),
        // });

        // localStorage.setItem('dock_up_messages', JSON.stringify(dockUpMessages));
      }
    }

    // Dock telemetry
    if(
      deviceType === deviceTypes.DOCK && 
      dockTelemetryGroups.find((group) => {
        const messageGroup = message.group.split('/').pop();
        return messageGroup === group;
      })
    ) {
      const groupParts = message.group.split('/');
      const messageTargetSerial = groupParts[groupParts.length - 2];

      if(
        lastTelemetryTimestamp.current[messageTargetSerial] &&
        Date.now() - lastTelemetryTimestamp.current[messageTargetSerial] < 500
      ) {
        return;
      }
      
      const messageTargetId = store.getState().deviceService.devices?.find((device) => device.serialNumber === messageTargetSerial && device.type === deviceTypes.DOCK) ? 
        message.targetId : 
        messageTargetSerial;

      dispatch(setDeviceTelemetries({
        deviceId: messageTargetId,
        data: message.data?.data,
      }));

      lastTelemetryTimestamp.current[messageTargetSerial] = Date.now();

      // send sub-device permission request
      const subDeviceDetails = message.data?.data?.sub_device;
      const subDeviceSerial = subDeviceDetails?.device_sn;
      const permissionRequestSent = store.getState().deviceService.subDevicePermissionRequestProgress[message.targetId];

      if(subDeviceSerial && !permissionRequestSent && groupsJoinStatus.current[message.targetId]) {
        dispatch(setSubDevicePermissionRequest({
          mainDeviceId: message.targetId, 
          subDeviceSerial
        }));
      };

      // add sub-device to devices list
      const deviceInfo = store.getState().deviceService.devices.find(item => item.id === message.targetId);
      const subDeviceCurrInfo = store.getState().deviceService.devices.find(item => item.serialNumber === subDeviceSerial);
      
      const subDeviceNewInfo = {
        id: subDeviceSerial,
        serialNumber: subDeviceSerial,
        name: 'Dock drone ' + deviceInfo?.serialNumber?.slice(-3)?.toUpperCase(),
        onlineStatus: subDeviceDetails?.device_online_status ? DeviceOnlineStatus.ONLINE : DeviceOnlineStatus.OFFLINE,
        coordinates: null,
        type: deviceTypes.DOCK_DRONE,
        updateTimestamp: Date.now(),
        details: {
          parentId: message.targetId,
        },
      };
      
      if(subDeviceSerial && !subDeviceCurrInfo) {
        dispatch(setDevices([...store.getState().deviceService.devices, subDeviceNewInfo]));
      }
      else if(subDeviceCurrInfo && subDeviceCurrInfo.type !== deviceTypes.DOCK_DRONE) {
        dispatch(setDeviceInfo({
          deviceId: subDeviceCurrInfo.id,
          data: {
            ...subDeviceCurrInfo,
            name: subDeviceNewInfo.name,
            type: subDeviceNewInfo.type,
            onlineStatus: subDeviceNewInfo.onlineStatus,
            updateTimestamp: Date.now(),
            details: {
              ...subDeviceCurrInfo.details,
              parentId: message.targetId,
            }
          }
        }));
      }
      else if(subDeviceSerial && subDeviceCurrInfo && subDeviceCurrInfo.onlineStatus !== subDeviceNewInfo.onlineStatus) {
        dispatch(setDeviceInfo({
          deviceId: subDeviceCurrInfo.id,
          data: {
            ...subDeviceCurrInfo,
            onlineStatus: subDeviceNewInfo.onlineStatus,
            updateTimestamp: Date.now(),
          }
        }));
      }

      // remove stream data for subdevice when it goes offline
      if(subDeviceDetails?.device_online_status === 0) {
        const subDeviceStreamConfig = store.getState().deviceService.dockConnections.find(item => item.deviceId === message.targetId)?.subStreamConfig;

        if(subDeviceStreamConfig) {
          dispatch(setDockConnectionInfo({
            deviceId: message.targetId,
            data: {
              subStreamConfig: null,
            }
          }));
        }
      }
    }

    // Dock HMS
    if(
      deviceType === deviceTypes.DOCK && 
      message.data?.method === 'hms'
    ) {
      const groupParts = message.group.split('/');
      const messageTargetSerial = groupParts[groupParts.length - 2];

      const messageTargetId = store.getState().deviceService.devices?.find((device) => device.serialNumber === messageTargetSerial && device.type !== deviceTypes.DOCK_DRONE) ? 
        message.targetId : 
        messageTargetSerial;

      dispatch(setDeviceHms({
        deviceId: messageTargetId,
        data: message.data?.data,
      }));
    }

    // Onboard telemetry & HMS
    if(
      deviceType === deviceTypes.DRONE && 
      onboardTelemetryGroups.find(group => message.group.split('-')[0] === group)
    ) {

      if(
        !lastTelemetryTimestamp.current[message.targetId] ||
        Date.now() - lastTelemetryTimestamp.current[message.targetId] >= 500
      ) {
        dispatch(setDeviceTelemetries({
          deviceId: message.targetId,
          data: message.data,
        }));

        if(initialTelemetriesReceived.current) {
          lastTelemetryTimestamp.current[message.targetId] = Date.now();
        }

        if(!initialTelemetriesTimeoutId.current) {
          initialTelemetriesTimeoutId.current = setTimeout(() => {
            initialTelemetriesReceived.current = true;
          }, 5000);
        }

        if(message.data?.hms) {
          dispatch(setDeviceHms({
            deviceId: message.targetId,
            data: message.data.hms,
          }));
        }
      }
    }
  }, [deviceService.dockConnections, deviceService.onboardConnections, devicesCombined, externalMessageHandlers, dispatch]);

  // Set combined device list
  useEffect(() => {
    dispatch(setDevices(devicesCombined));
  }, [devicesCombined]);

  // Create dock connection
  const createDockConnection = (id) => {
    const currConnection = store.getState().deviceService.dockConnections.find((dock) => dock.deviceId === id);

    if (currConnection && currConnection.status !== deviceConnectionStatus.DISCONNECTED)
      return currConnection;

    dispatch(setDockConnectionInfo({
      deviceId: id,
      data: {
        status: deviceConnectionStatus.INITIALIZING,
      }
    }));

    dispatch(startDockConnection(id, {
      deviceId: id,
    }));
  }

  // Create onboard connection
  const createOnboardConnection = (id) => {
    const currConnection = store.getState().deviceService.onboardConnections.find((item) => item.deviceId === id);

    if (currConnection && currConnection.status !== deviceConnectionStatus.DISCONNECTED)
      return currConnection;

    dispatch(setOnboardConnectionInfo({
      deviceId: id,
      data: {
        status: deviceConnectionStatus.INITIALIZING,
      }
    }));

    dispatch(startOnboardConnection(id, {
      deviceId: id,
    }));
  }

  // Create mobile connection
  const createMobileConnection = () => {
    const currConnection = store.getState().deviceService.mobileConnection;

    if (currConnection && currConnection.status !== deviceConnectionStatus.DISCONNECTED)
      return currConnection;

    dispatch(setMobileConnectionInfo({
      deviceId: frontendOid,
      status: deviceConnectionStatus.INITIALIZING,
    }));

    dispatch(startMobileConnection());
  }

  // Join to sub device groups
  const joinDockGroups = (deviceId, groups, subDevice = false) => {
    const targetCandidateGroups = subDevice ? dockSubDeviceDefaultJoinGroups : dockDefaultJoinGroups;

    targetCandidateGroups?.forEach((group) => {
      const fullGroupName = groups?.[group];

      if(fullGroupName)
        dockConnectionManager.joinGroup(deviceId, fullGroupName);
    });

    groupsJoinStatus.current[deviceId] = true;
  }

  // Request and set permission groups for dock sub-device
  const getSubDevicePermissions = useCallback((mainDeviceId, subDeviceSerial) => {
    if(store.getState().deviceService.subDevicePermissionRequestProgress[mainDeviceId]) 
      return;

    const mainDeviceConnection = store.getState().deviceService.dockConnections.find(
      item => item.deviceId === mainDeviceId
    );
    
    if(!mainDeviceConnection?.group || mainDeviceConnection.group.subDevice)
      return;

    const targetGroup = mainDeviceConnection.group.send?.['commands'];
    const targetReplyGroup = mainDeviceConnection.group.join?.['commands_reply'];

    const mainDeviceSerial = store.getState().deviceService.devices.find(
      item => item.id == mainDeviceId
    )?.serialNumber;

    if(!targetGroup || !targetReplyGroup || !mainDeviceSerial)
      return;

    dockConnectionManager.unsubscribeGroupMessages(subDeviceMessageIds.current[mainDeviceId]);
    subDeviceMessageIds.current[mainDeviceId] = dockConnectionManager.subscribeGroupMessages([
      {
        identity: mainDeviceId,
        name: [targetReplyGroup],
        handler: (message) => {
          if(
            message.method === djiCloudCustomMethod.flight.GRANT_SUB_DEVICE_PERMISSIONS && 
            message.bid === store.getState().deviceService.subDevicePermissionRequestProgress[mainDeviceId]?.requestBid && 
            message.data?.group
          ) {
            dispatch(setDockConnectionInfo({
              deviceId: mainDeviceId,
              data: {
                group: {
                  ...mainDeviceConnection.group,
                  subDevice: message.data.group,
                }
              }
            }));

            const joinGroups = message.data.group?.join;

            if(joinGroups)
              joinDockGroups(mainDeviceId, joinGroups, true);

            console.log('📦 SUB_DEVICE_PERMISSION_RESULT', message.data);
          }
        },
      },
    ], 'device-service');

    const requestBid = generateId();

    dispatch(setSubDevicePermissionRequestProgress({
      deviceId: mainDeviceId,
      data: {
        requestTimestamp: Date.now(),
        requestBid,
      }
    }));

    dockConnectionManager.sendToDjiCloudGroup(mainDeviceId, targetGroup, {
      method: djiCloudCustomMethod.flight.GRANT_SUB_DEVICE_PERMISSIONS,
      bid: requestBid,
      data: {
        "connectionId": mainDeviceConnection.connectionId,
        "serialNumber": subDeviceSerial
      },
      gateway: mainDeviceSerial,
    });
  }, [deviceService.subDevicePermissionRequestProgress]);

  const queryDockMissionStatus = (deviceId, group) => {
    setTimeout(() => {
      const serialNumber = devicesCombined.find(item => item.id === deviceId)?.serialNumber;

      dockConnectionManager?.sendToGroup(deviceId, group, {
        method: djiCloudCustomMethod.mission.MISSION_QUERY,
        data: {},
        gateway: serialNumber,
      });
    }, 1000);
  }

  // Docks list changes
  useEffect(() => {
    if (docks) {
      docks.forEach((dock) => {
        if (dock.onlineStatus === DeviceOnlineStatus.ONLINE) {
          createDockConnection(dock.id);
        } else if (store.getState().deviceService.dockConnections.find((item) => item.deviceId === dock.id)) {
          dispatch(removeDockConnectionInfo(dock.id));
    
          dispatch(setDeviceTelemetries({
            deviceType: deviceTypes.DOCK,
            deviceId: dock.id,
            data: null,
          }));
        }
      });
    }
  }, [docks]);

  // Dock connection result
  useEffect(() => {
    const result = store.getState().deviceService.dockConnectionProgress;

    if (result && result.status === 'success') {
      dispatch(setDockConnectionInfo({
        deviceId: result.meta.deviceId,
        data: {
          deviceId: result.meta.deviceId,
          connectionUrl: result.data.newUrl,
          status: deviceConnectionStatus.CONNECTING,
          group: {
            ...result.data.dockPermissions,
            ...(Object.keys(result.data.dronePermissions?.join || {})?.length ? {
              subDevice: result.data.dronePermissions
            } : {})
          }
        }
      }));

      dockConnectionManager.create(result.data.newUrl, () => {
        dispatch(cleanSubDevicePermissionRequest(result.meta.deviceId));

        dispatch(setDeviceInfo({
          deviceId: result.meta.deviceId,
          data: {
            initialized: false,
          }
        }));

        dispatch(setDockConnectionInfo({
          deviceId: result.meta.deviceId,
          data: {
            status: deviceConnectionStatus.CONNECTED,
          }
        }));

        // Additional delay for connection establishment
        setTimeout(() => {
          const connectionId = dockConnectionManager.getConnectionId(result.meta.deviceId);

          if(result.data.dockPermissions) {
            const joinGroups = result.data.dockPermissions.join;

            if(joinGroups)
              joinDockGroups(result.meta.deviceId, joinGroups);

            queryDockMissionStatus(result.meta.deviceId, result.data.dockPermissions.send?.commands);
          } else {
            dispatch(getDockWsPermissions({
              id: result.meta.deviceId,
              connectionId
            }, { deviceId: result.meta.deviceId }));
          }

          dispatch(setDockConnectionInfo({
            deviceId: result.meta.deviceId,
            data: {
              connectionId,
            }
          }))
        }, 2000);
      },
      () => {
        console.info("🔃 Duck Client Reconnecting...", result.meta.deviceId)
      });
    }
    else {
      console.log('Dock connection failed', result);
    }
      
  }, [deviceService.dockConnectionProgress]);

  // Dock connection permissions result
  useEffect(() => {
    const result = store.getState().deviceService.dockWsPermissionProgress;

    if(result && result.status === 'success') {
      dispatch(setDockConnectionInfo({
        deviceId: result.meta?.deviceId,
        data: {
          group: result.data.group,
        }
      }));

      const joinGroups = result.data.group?.join;

      if(joinGroups)
        joinDockGroups(result.meta.deviceId, joinGroups);

      queryDockMissionStatus(result.meta.deviceId, result.data.group?.send?.commands);
    }
  }, [deviceService.dockWsPermissionProgress]);

  // Sub-device permission request
  useEffect(() => {
    const { mainDeviceId, subDeviceSerial } = store.getState().deviceService.subDevicePermissionRequest || {};

    if(!mainDeviceId || !subDeviceSerial)
      return;

    getSubDevicePermissions(mainDeviceId, subDeviceSerial);
  }, [deviceService.subDevicePermissionRequest]);

  // Onboard list changes
  useEffect(() => {
    if (onboardDevices) {
      onboardDevices.forEach((device) => {
        if (device.onlineStatus === DeviceOnlineStatus.ONLINE) {
          createOnboardConnection(device.id);
        } else if (store.getState().deviceService.onboardConnections.find((item) => item.deviceId === device.id)) {
          dispatch(setOnboardConnectionInfo({
            deviceId: device.id,
            data: {
              status: deviceConnectionStatus.DISCONNECTED,
            }
          }));

          dispatch(setDeviceTelemetries({
            deviceType: deviceTypes.DRONE,
            deviceId: device.id,
            data: null,
          }));
        }
      });
    }
  }, [onboardDevices]);

  // Onboard connection result
  useEffect(() => {
    const result = store.getState().deviceService.onboardConnectionProgress;

    if (result && result.status === 'success') {
      dispatch(setOnboardConnectionInfo({
        deviceId: result.meta.deviceId,
        data: {
          connectionUrl: result.data.url,
          status: deviceConnectionStatus.CONNECTING,
        }
      }));

      onboardConnectionManager.create(result.data.url, () => {
        dispatch(setOnboardConnectionInfo({
          deviceId: result.meta.deviceId,
          data: {
            status: deviceConnectionStatus.CONNECTED,
          }
        }));

        dispatch(setDeviceInfo({
          deviceId: result.meta.deviceId,
          data: {
            initialized: false,
          }
        }));

        // Additional delay for connection establishment
        setTimeout(() => {
          dispatch(getOnboardWsPermissions({
            ids: [result.meta.deviceId],
            connectionId: onboardConnectionManager.getConnectionId(result.meta.deviceId),
          }, { deviceId: result.meta.deviceId }));
        }, 2000);
      });
    }
  }, [deviceService.onboardConnectionProgress]);

  // Onboard connection permissions result
  useEffect(() => {
    const result = store.getState().deviceService.onboardWsPermissionProgress;

    if(result && result.status === 'success') {
      dispatch(setOnboardConnectionInfo({
        deviceId: result.meta?.deviceId,
        data: {
          group: result.data.group,
        }
      }));

      onboardDefaultJoinGroups.forEach((group) => {
        const fullGroupName = result.data.group?.join?.[group];

        if(fullGroupName)
          onboardConnectionManager.joinGroup(result.meta.deviceId, fullGroupName);
      });

      setTimeout(() => {
        frontendConnectionManager?.sendEvent(
          frontendOid,
          HubEventName.CHECK_DRONE_STATES,
          {
            action: HubEventName.TELEMETRIES_STATUS,
            userId: result.meta.deviceId,
            platform: HubName.FRONTEND,
            data: {
              userId: frontendOid,
              deviceId: result.meta.deviceId,
              corelationId: "FrontendHubProvider",
            },
          }
        );

        onboardConnectionManager?.sendToGroup(result.meta.deviceId, result.data.group?.send?.commandChannel, {
          waypoint: {
            actionId: onboardWaypointAction.MISSION_QUERY,
          }
        });
      }, 2000);
    }
  }, [deviceService.onboardWsPermissionProgress]);

  // Mobile connection result
  useEffect(() => {
    const result = store.getState().deviceService.mobileConnectionProgress;

    if (result && result.status === 'success') {  
      dispatch(setMobileConnectionInfo({
        deviceId: frontendOid,
        connectionUrl: result.data.url,
        status: deviceConnectionStatus.CONNECTING,
      }));

      mobileConnectionManager.create(result.data.url, () => {
        dispatch(setMobileConnectionInfo({
          deviceId: frontendOid,
          data: {
            status: deviceConnectionStatus.CONNECTED,
          }
        }));

        // Additional delay for connection establishment
        setTimeout(() => {
          dispatch(getMobileWsPermissions({
            platform: 'frontend',
            connectionId: mobileConnectionManager.getConnectionId(false),
          }));
        }, 2000);
      });
    }
  }, [deviceService.mobileConnectionProgress]);

  // Mobile connection permissions result
  useEffect(() => {
    const result = store.getState().deviceService.mobileWsPermissionProgress;

    if(result && result.status === 'success') {
      dispatch(setMobileConnectionInfo({
        deviceId: frontendOid,
        data: {
          group: result.data.group,
        }
      }));

      mobileDefaultJoinGroups.forEach((group) => {
        const fullGroupName = result.data.group?.join?.[group];

        if(fullGroupName)
          mobileConnectionManager.joinGroup(frontendOid, fullGroupName);
      });
    }
  }, [deviceService.mobileWsPermissionProgress]);

  useEffect(() => {
    dockConnectionManager.subscribeMessages([
      (message) => onMessage(message, deviceTypes.DOCK)
    ], 'device_service');

    onboardConnectionManager.subscribeMessages([
      (message) => onMessage(message, deviceTypes.DRONE)
    ], 'device_service');

    mobileConnectionManager.subscribeMessages([
      (message) => onMessage(message, deviceTypes.MOBILE)
    ], 'device_service');

    // Mobile devices are not pre-defined and we use a single
    // connection to observe all mobile devices based on the messages
    createMobileConnection();
  }, []);

  return {
    docks,
    onboardDevices,
    devices: devicesCombined,
  };
}