import { useDispatch, useSelector } from 'react-redux';
import styles from './ExploreApp.module.sass';
import { useEffect, useRef, useState } from 'react';
import SidePanel from 'src/hci/organism/SidePanel/SidePanel';
import Loading from 'src/ui/Loading/Loading';
import DeviceMap from 'src/components/DeviceMap/DeviceMap';
import CollapsablePanel from './common/CollapsablePanel/CollapsablePanel';
import { deviceTypes } from 'src/helper/constants';
import { headerBreadcrumb } from 'src/store/UiSlice';
import { setContext } from 'src/redux/ducks/appContextSlice';
import { setSelectedEntity } from './ExploreAppSlice';
import { DeviceMapMarkerTypes } from 'src/components/DeviceMap/DeviceMap';
import { setActivePathId, setLastSelectedMarker } from 'src/components/DeviceMap/DeviceMapSlice';
import EntityListItem from './EntityListItem/EntityListItem';
import useMobileClients from 'src/services/user/common/useMobileClients';
import { TbPlus } from 'react-icons/tb';
import AddDockDialog from '../DeviceApp/DockManagerApp/AddDockDialog/AddDockDialog';
import Dialog from 'src/hci/common/Dialog/Dialog';
import DeviceOnboarding from '../DeviceApp/OnboardDeviceApp/DeviceOnboarding/DeviceOnboarding';
import AddUnit from '../SecurityApp/AddUnit/AddUnit';
import useUnitList from '../SecurityApp/common/useUnitList';
import { getDeviceInfo } from 'src/services/device/common/deviceUtils';
import OperationDashboard from './OperationDashboard/OperationDashboard';

export const EntityType = {
  DOCK: 'dock',
  DRONE: 'drone',
  MOBILE_DEVICE: 'mobile_device',
  OPERATION_UNIT: 'operation_unit'
};

const EntityTypeToDeviceMapMarker = {
  [EntityType.DOCK]: DeviceMapMarkerTypes.DOCK,
  [EntityType.DRONE]: DeviceMapMarkerTypes.DRONE,
  [EntityType.MOBILE_DEVICE]: DeviceMapMarkerTypes.MOBILE_DEVICE,
  [EntityType.OPERATION_UNIT]: DeviceMapMarkerTypes.OPERATION_UNIT
};

function ExploreApp() {
  const dispatch = useDispatch();
  const allDevices = useSelector((state) => state.deviceService.devices);
  const {data: operationUnits, refresh: refreshUnitList} = useUnitList();
  const selectedEntity = useSelector((state) => state.exploreApp.selectedEntity);
  const selectedMapMarker = useSelector((state) => state.deviceMap.lastSelectedMarker);
  const mobileClients = useMobileClients();
  const [showDroneOnboarding, setShowDroneOnboarding] = useState(false);
  const [showAddUnitPanel, setShowAddUnitPanel] = useState(false);

  const handleUnitAddSuccess = () => {
    setShowAddUnitPanel(false);
    refreshUnitList();
  }

  useEffect(() => {
    if (
      !selectedMapMarker || 
      !Object.values(EntityTypeToDeviceMapMarker).includes(selectedMapMarker.type) ||
      getDeviceInfo(selectedMapMarker.data?.sourceId)?.details?.parentId
    ) return;

    dispatch(setSelectedEntity({
      id: selectedMapMarker.data?.sourceId,
      ...selectedMapMarker.data?.raw,
      entityType: EntityTypeToDeviceMapMarker[selectedMapMarker.type] //TODO: Fix - entity type should be inverse!
    }));
  }, [selectedMapMarker]);

  useEffect(() => {
    dispatch(headerBreadcrumb([
      {title: "Dashboard", link: "/dashboard"},
      {title: "Explore App", link: "/dashboard/explore-app"}
    ]))

    dispatch(
      setContext({
        theme: { color: "#2699fb", name: "explore", highlight: "#00b93811" },
      })
    );
  }, []);

  const dockTools =<Dialog 
    title="Add Dock"
    trigger={(
      <div><TbPlus /></div>
    )}
  >
    <AddDockDialog />
  </Dialog>;

  const droneTools = [
    <TbPlus title="Add Drone" onClick={() => setShowDroneOnboarding(true)} key="add" />
  ];

  const unitTools = [
    <TbPlus title="Add Unit" onClick={() => setShowAddUnitPanel(true)} key="add" />
  ];

  return (
    <div className={styles.exploreApp}>
      <SidePanel className={styles.entityListPanel}  smallCollapseButton emptyHeader>
        <CollapsablePanel title="Dock Stations" className={styles.resourceGroup} noStyle tools={dockTools}>
          {allDevices?.filter(item => item.type === deviceTypes.DOCK)?.map(item => (
            <EntityListItem entity={item} type={EntityType.DOCK} key={item.id} />
          ))}
        </CollapsablePanel>
        <CollapsablePanel title="Drones" className={styles.resourceGroup} noStyle tools={droneTools}>
          {allDevices?.filter(item => item.type === deviceTypes.DRONE)?.map(item => (
            <EntityListItem entity={item} type={EntityType.DRONE} key={item.id} />
          ))}
        </CollapsablePanel>
        <CollapsablePanel title="Mobile Devices" className={styles.resourceGroup} noStyle>
          {mobileClients?.map(item => (
            <EntityListItem entity={item} type={EntityType.MOBILE_DEVICE} key={item.id} />
          ))}
        </CollapsablePanel>
        <CollapsablePanel title="Operation Units" className={styles.resourceGroup} noStyle tools={unitTools}>
          {operationUnits?.map(item => (
            <EntityListItem entity={item} type={EntityType.OPERATION_UNIT} key={item.id} />
          ))}
        </CollapsablePanel>
      </SidePanel>
      <OperationDashboard />

      {showDroneOnboarding && (
        <DeviceOnboarding
          onCloseByUser={() => setShowDroneOnboarding(false)}
        />
      )}

      {showAddUnitPanel && (
        <AddUnit onCancel={() => setShowAddUnitPanel(false)} onSuccess={handleUnitAddSuccess} />
      )}
    </div>
  )
}

export default ExploreApp