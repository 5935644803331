import { MdOutlineMyLocation } from 'react-icons/md';
import './DeviceMapToolbar.sass';
import { IoEarth } from 'react-icons/io5';
import { RiZoomInLine } from 'react-icons/ri';
import classNames from 'classnames';

export default function DeviceMapToolbar({ extended, onRecenterClick, onDeviceZoomClick, onZoomOutClick, edgeSenseEnabled }) {
  return (
    <div className={classNames("device-map-toolbar", { extended })}>
      {!!onRecenterClick && (
        <button 
          className={classNames("toolbar-item toggle", { active: edgeSenseEnabled })} 
          title="Recenter Map / Auto Recenter on Edges" 
          onClick={onRecenterClick}
        >
          <MdOutlineMyLocation className="icon" />
        </button>
      )}
      {!!onDeviceZoomClick && (
        <button 
          className="toolbar-item" 
          title="Zoom to Selected Device" 
          onClick={onDeviceZoomClick}
        >
          <RiZoomInLine className="icon" />
        </button>
      )}
      {!!onZoomOutClick && (
        <button 
          className="toolbar-item" 
          title="Zoom Out to Show All Devices" 
          onClick={onZoomOutClick}
        >
          <IoEarth className="icon" />
        </button>
      )}
    </div>
  )
}